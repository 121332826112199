import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FisAccountTypes } from '@enum/fis-account-types';
import { environment } from '@env';
import { Payment, PaymentResultRaw } from '@interface/payment';
import { PaymentMethodRequest } from '@interface/payment-method';
import { Observable } from 'rxjs';
const { apigeeApiKey, apigeeEndpoint } = environment;

@Injectable()
export class ApigeeService {
  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  public createPayment(payment: Payment, accountId: string): Observable<PaymentResultRaw> {
    const url = `${apigeeEndpoint}/v3/admin/accounts/${accountId}/payments`;

    return this.http.post(url, payment, {
      headers: {
        'x-api-key': apigeeApiKey
      }
    });
  }

  public createPaymentMethod(paymentMethodRequest: PaymentMethodRequest): Observable<any> {
    const url = `${apigeeEndpoint}/v3/admin/payment-methods`;

    return this.http.post(url, paymentMethodRequest, {
      headers: {
        'x-api-key': apigeeApiKey
      }
    });
  }

  public creditCardType(number: string) {
    if (number) {
      const creditCardNumber = number.toString();

      let re = /^4/;

      if (creditCardNumber.match(re) != null) {
        return FisAccountTypes.VISA;
      }

      const regExPart1 = '5[1-5]\\d{2}';
      const regExPart2 = '2(2[2-9]\\d|2[3-9]\\d{2}|[3-6]\\d{3}|7([0-1]\\d|20))';
      const regExPart3 = '\\d{12}$';

      re = new RegExp(`^(${regExPart1}|${regExPart2})${regExPart3}`);

      if (creditCardNumber.match(re)) {
        return FisAccountTypes.MASTERCARD;
      }

      re = /^3[47]/;

      if (creditCardNumber.match(re) != null) {
        return FisAccountTypes.AMEX;
      }

      re = /^(6011|622(12[6-9]|1[3-9]\d|[2-8]\d{2}|9[0-1]\d|92[0-5]|64[4-9])|65)/;

      if (creditCardNumber.match(re) != null) {
        return FisAccountTypes.DISCOVER;
      }

      re = /^35(2[89]|[3-8]\d)/;

      if (creditCardNumber.match(re) != null) {
        return FisAccountTypes.JCB;
      }
    }

    return FisAccountTypes.PINLESS_DEBIT;
  }

  public creditCardNickName(accountType: string, creditCardNumber: string) {
    const lastFour = creditCardNumber.substring(creditCardNumber.length - 4);
    const datePart = this.datePipe.transform(new Date(), 'MMddyyyy');

    return `${accountType}${lastFour} ${datePart}`;
  }

  public creditCardName(firstName: string, middleInitial: string, lastName: string): string {
    const names = [firstName];

    if (middleInitial) {
      names.push(middleInitial);
    }

    names.push(lastName);

    return names.join(' ').trim();
  }

  public bankNickName(accountType: string, bankName: string) {
    const bankPart = bankName
      .replace(/[\W_]+/g, ' ')
      .substring(0, 5)
      .trim();
    const datePart = this.datePipe.transform(new Date(), 'MMddyyyy');

    return `${accountType} ${bankPart} ${datePart}`;
  }
}

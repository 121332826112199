import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BankInfo, PaymentMethodInfo, PaymentMethodRequest } from '@interface/payment-method';
import { ModelFormGroup } from '@interface/form-type.model';
import { ApigeeService } from '@service/apigee/apigee.service';
import { FisService } from '@service/fis/fis.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit {
  @Input() public hideSubmit = false;
  @Input() public hideSave = true;
  @Input() public parentForm: ModelFormGroup<PaymentMethodInfo>;
  @Output() public closeBankAccount = new EventEmitter<boolean>();
  @Output() public checkValidity: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public isSubmitting = false;
  public fisUsername: string;
  public accountHolderName: string;

  public form: ModelFormGroup<PaymentMethodInfo>;
  public bankGroup: ModelFormGroup<BankInfo>;
  public accountTypeControl = new FormControl('', Validators.required);
  public saveBankControl: FormControl<boolean>;

  protected submissionError: string = null;
  public bankCheckMaskedAccountNumber = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private fisService: FisService,
    private apigeeService: ApigeeService
  ) {
    this.saveBankControl = new FormControl(false);
    this.readQueryParams();
  }

  public createForm() {
    this.form = this.parentForm ? this.parentForm : this.fb.group({});
    this.bankGroup = this.fb.group({});
    this.form.addControl('accountType', this.accountTypeControl);
    this.form.addControl('bankInfo', this.bankGroup);
    this.form.addControl('saveBank', this.saveBankControl);
  }

  public readQueryParams() {
    this.route.queryParams.subscribe(({ cdhCustomerId, accountHolderName }) => {
      if (cdhCustomerId) {
        this.fisService
          .getFisUsername(cdhCustomerId)
          .subscribe((fisUsername) => (this.fisUsername = fisUsername));
      }

      if (accountHolderName) {
        this.accountHolderName = accountHolderName;
      }
    });
  }

  public onChanges() {
    this.form.valueChanges.subscribe(() => {
      this.checkValidity.emit(this.form.valid);
    });
  }

  public ngOnInit(): void {
    this.createForm();
    this.onChanges();
  }

  public addBankAccount() {
    const paymentMethodInfo = new PaymentMethodInfo(this.form.value);
    const bankInfo = paymentMethodInfo.bankInfo;

    // adjust bank fields to be strings
    bankInfo.routingNumber = String(bankInfo.routingNumber);
    bankInfo.accountNumber = bankInfo.accountNumber.trim();

    // create nickname
    paymentMethodInfo.accountNickName = this.apigeeService.bankNickName(
      paymentMethodInfo.accountType,
      bankInfo.bankName
    );

    const paymentMethodRequest = new PaymentMethodRequest({
      hasAgreedToStore: 'true',
      hasAgreedToUse: 'true',
      externalId: this.fisUsername,
      paymentMethodInfo
    });

    this.submissionError = null;
    this.isSubmitting = true;
    this.form.disable({ emitEvent: false });

    this.apigeeService
      .createPaymentMethod(paymentMethodRequest)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe(
        (resp) => {
          if (resp.errors && resp.errors.length > 0) {
            const errors = resp.errors.map((err) => err?.ApplMsgTxt).filter(Boolean);

            this.submissionError = errors.join('<br />');
            this.form.enable({ emitEvent: false });

            return;
          }

          this.closeBankAccount.emit(true);
        },
        (error) => {
          this.submissionError =
            error?.error?.errors?.[0]?.message ||
            error?.statusText ||
            'There was an error processing your request';
        }
      );
  }

  public updateAccountNumber(accountNumber: string) {
    this.bankCheckMaskedAccountNumber = accountNumber;
  }
}

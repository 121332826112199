import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  public getFlag(featureFlag: string): Observable<boolean> {
    if (environment.features[featureFlag] !== undefined) {
      return of(environment.features[featureFlag]);
    }

    return of(true);
  }
}

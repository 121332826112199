import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  UrlTree
} from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public loginRouteKey = 'db-login-route';
  public showOverlay = true;
  private _loginRoute: string;
  private readonly _destroying$ = new Subject<void>();

  private get loginRoute(): string {
    return this._loginRoute;
  }
  private set loginRoute(route: string) {
    this._loginRoute = route;
    localStorage.setItem(this.loginRouteKey, JSON.stringify(this.loginRoute));
  }

  constructor(
    private broadcastService: MsalBroadcastService,
    private router: Router,
    public authService: MsalService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    const loginRoute = localStorage.getItem(this.loginRouteKey);

    if (loginRoute && loginRoute.length) {
      this.loginRoute = loginRoute;
    }
  }

  public navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }

    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the skeleton in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }

    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

  public ngOnInit() {
    this.router.events
      .pipe(filter((eventType) => eventType instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        // Ignore MSAL token routing
        if (!event.url.includes('#access_token') && !event.url.includes('#id_token')) {
          this.loginRoute = event.url.replace(/^https?:\/\/[^/]+/, '');
        }
      });

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        const activeAccount = this.authService?.instance?.getAllAccounts()[0];

        this.authService?.instance?.setActiveAccount(activeAccount);
        const loginRoute = this.loginRoute;
        let urlTree: UrlTree;

        if (loginRoute) {
          urlTree = this.router.parseUrl(loginRoute);
        }

        if (urlTree) {
          this.router.navigateByUrl(urlTree);
        }
      });
  }

  public ngOnDestroy() {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}

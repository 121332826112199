import { FormControl, Validators } from '@angular/forms';

export class NumberValidators extends Validators {
  public static numericMaxLength = (maxLength: number) => (control: FormControl) =>
    control.value && control.value.toString().length > maxLength
      ? { numericMaxLength: { requiredLength: maxLength } }
      : null;

  public static numericMinLength = (minLength: number) => (control: FormControl) =>
    control.value && control.value.toString().length < minLength
      ? { numericMinLength: { requiredLength: minLength } }
      : null;
}

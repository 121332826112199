import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-payment-method-dialog',
  styleUrls: ['./new-payment-method-dialog.component.scss'],
  templateUrl: './new-payment-method-dialog.component.html'
})
export class NewPaymentMethodDialogComponent {
  constructor(public dialogRef: MatDialogRef<NewPaymentMethodDialogComponent>) {}

  public dismissDialog() {
    this.dialogRef.close(false);
  }

  public completeDialog() {
    this.dialogRef.close(true);
  }
}

import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public logo = 'logo';
  public version = '';

  constructor(public authService: MsalService) {}

  public logout(): void {
    localStorage.clear();
    this.authService.logout();
  }

  public onShowVersion(): void {
    this.version = `V${environment.VERSION}`;
    setTimeout(() => {
      this.version = '';
    }, 3000);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Topic, TroubleshootingService } from '@service/troubleshooting/troubleshooting.service';

@Component({
  selector: 'app-topic-container',
  templateUrl: './topic.component.html'
})
export class TopicComponent implements OnInit {
  public topic: Topic;

  constructor(
    private route: ActivatedRoute,
    private troubleshootingService: TroubleshootingService
  ) {}

  public ngOnInit() {
    this.route.params.subscribe(({ id }) => {
      this.topic = this.troubleshootingService.getTopic(id);
    });
  }
}

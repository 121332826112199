import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  protected errorCode: number;
  protected returnUrl: UrlTree;

  private destroyed$: Subject<void> = new Subject();

  constructor(private route: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(({ r }) => {
      this.returnUrl = r ? this.router.parseUrl(atob(r)) : null;
    });

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(({ code }) => {
      this.errorCode = code;
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
  }
}

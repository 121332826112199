import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import { from as observableFrom, Observable, of as observableOf } from 'rxjs';

import { environment } from '@env';
import { map, mergeMap } from 'rxjs/operators';

interface GraphUser {
  givenName: string;
  surname: string;
  permissions: any[];
  userPrincipalName: string;
}

@Injectable()
export class GraphService {
  public user: GraphUser;
  private permissionGroups: any[];
  private client: MicrosoftGraphClient.Client;

  constructor(public authService: MsalService) {
    this.permissionGroups = environment.azureAd.groups;
  }

  public getUserProfile() {
    return observableFrom(
      this.getClient().then((client) =>
        client
          .api('/me')
          .get()
          .then(async (user: GraphUser) => {
            await this.getUserGroups().then(({ value: groups }) => {
              user.permissions = this.filterPermissions(groups);
            });

            this.user = user;

            return user;
          })
      )
    );
  }

  private getUserGroups() {
    return this.getClient().then((client) => client.api('/me/memberOf').get());
  }

  private async getClient() {
    if (!this.client) {
      await this.setClient();
    }

    return this.client;
  }

  private async setClient() {
    return this.getToken()
      .toPromise()
      .then((token) => {
        this.client = MicrosoftGraphClient.Client.init({
          authProvider: (done) => done(null, token)
        });

        return this.client;
      });
  }

  public getToken(): Observable<string> {
    return observableFrom(this.authService.acquireTokenSilent({ scopes: ['user.read'] })).pipe(
      mergeMap((response) => {
        if (!response || !response.accessToken) {
          return observableFrom(this.authService.acquireTokenPopup({ scopes: ['user.read'] })).pipe(
            map((tokenResponse) => {
              return observableOf(tokenResponse.accessToken);
            })
          );
        }

        return observableOf(response.accessToken);
      })
    ) as Observable<string>;
  }

  private filterPermissions(groups: any[]) {
    return groups.filter(
      (group: any) =>
        group.id === this.permissionGroups[0].id || group.id === this.permissionGroups[1].id
    );
  }
}

import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMaskCcDateAndCvv]'
})
export class MaskCcDateAndCvvDirective {
  @Output() public num = new EventEmitter();
  public ccDateStored: any = null;
  public ccCvvStored: any = null;

  constructor(private el: ElementRef) {}

  @HostListener('focus') public onFocus() {
    if (this.ccDateStored) {
      this.el.nativeElement.value = this.ccDateStored;
      document.getElementById('cardDateFront').innerHTML = this.ccDateStored;
    }

    if (this.ccCvvStored) {
      this.el.nativeElement.value = this.ccCvvStored;
    }
  }

  @HostListener('blur') public onBlur() {
    let el: string = this.el.nativeElement.value;

    if ((el.length === 3 || el.length === 4) && !el.includes('/')) {
      this.ccDateStored = '';
      this.ccCvvStored = el;
      el = el.replace(/./g, '*');
      this.el.nativeElement.value = el;
    } else {
      this.ccCvvStored = '';
      this.ccDateStored = el;

      if (el.length === 0) {
        document.getElementById('cardDateFront').innerHTML = 'MM/YY';
      } else {
        el = el.replace(/./g, '*');
        this.el.nativeElement.value = el;
        document.getElementById('cardDateFront').innerHTML = el;
      }
    }
  }
}

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@service/feature-flag/feature-flag.service';

@Directive({
  selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  // Concept from: https://medium.com/@amcdnl/feature-toggling-with-angular-and-the-angular-cli-eccf38369b3f

  @Input('appFeatureFlag')
  private featureToggle: string;

  // structural directive syntactic sugar mandates we prefix with the directive
  // name, this would just get confusing internally
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appFeatureFlagElse')
  private disabledTemplate: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlags: FeatureFlagService
  ) {}

  public ngOnInit(): void {
    this.featureFlags.getFlag(this.featureToggle).subscribe((featureEnabled) => {
      if (featureEnabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.disabledTemplate) {
        this.viewContainer.createEmbeddedView(this.disabledTemplate);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}

/* eslint:disable max-classes-per-file */
import { User } from '@interface/auth0-user';

enum CorruptionErrorMessage {
  'missingFisAccount' = 'Missing FIS Account',
  'missingCdhAccount' = 'Missing CDH Account',
  'unnecessaryFisEnrollment' = 'Unnecessary FIS Enrollment'
}

export class InvoiceAmount {
  public amount: number;
  public currency: string;
  public display: string;

  constructor(raw: InvoiceAmountRaw) {
    this.amount = raw.amount;
    this.currency = raw.currency;
    this.display = raw.display;
  }
}

export interface InvoiceAmountRaw {
  amount?: number;
  currency?: string;
  display?: string;
}

export class FisAccount {
  public accountId: string;
  public atmEligibility: boolean;
  public creditAvailable: any;
  public creditEligibility: boolean;
  public creditLimit: any;
  public currentBalance: any;
  public currentMinAmount: any;
  public debitEligibility: boolean;
  public hasPreAuthorizedPayments: boolean;
  public hasStoppedPayment: boolean;
  public associationStatus: boolean;
  public isLocked: boolean;
  public signatureDebitEligibility: boolean;

  constructor(raw: FisAccountRaw) {
    this.accountId = raw.accountId;
    this.atmEligibility = raw.atmEligibility;
    this.creditAvailable = raw.creditAvailable;
    this.creditEligibility = raw.creditEligibility;
    this.creditLimit = raw.creditLimit;
    this.currentBalance = raw.currentBalance;
    this.currentMinAmount = raw.currentMinAmount;
    this.debitEligibility = raw.debitEligibility;
    this.hasPreAuthorizedPayments = raw.hasPreAuthorizedPayments;
    this.hasStoppedPayment = raw.hasStoppedPayment;
    this.associationStatus = raw.associationStatus;
    this.isLocked = raw.isLocked;
    this.signatureDebitEligibility = raw.signatureDebitEligibility;
  }
}

export interface FisAccountRaw {
  accountId?: string;
  atmEligibility?: boolean;
  creditAvailable?: any;
  creditEligibility?: boolean;
  creditLimit?: any;
  currentBalance?: any;
  currentMinAmount?: any;
  debitEligibility?: boolean;
  hasPreAuthorizedPayments?: boolean;
  hasStoppedPayment?: boolean;
  associationStatus?: boolean;
  isLocked?: boolean;
  signatureDebitEligibility?: boolean;
}

export class CdhAccount {
  public category: string;
  public cdhAccountId: string;
  public currentAccountBalance?: InvoiceAmount;
  public infoProAccountId: string;
  public isSummaryBilled: false;
  public lastInvoiceAmount: InvoiceAmount;
  public name: string;
  public nextInvoiceDate: string;
  public serviceInterruptedOnDate: string;
  public startDate: string;
  public status: string;
  public webProfile: string;

  constructor(raw: CdhAccountRaw) {
    this.category = raw.category;
    this.cdhAccountId = raw.cdhAccountId;

    if (raw.currentAccountBalance) {
      this.currentAccountBalance = new InvoiceAmount(raw.currentAccountBalance);
    }

    if (raw.lastInvoiceAmount) {
      this.lastInvoiceAmount = new InvoiceAmount(raw.lastInvoiceAmount);
    }

    this.infoProAccountId = raw.infoProId;
    this.isSummaryBilled = raw.isSummaryBilled;
    this.name = raw.name;
    this.nextInvoiceDate = raw.nextInvoiceDate;
    this.serviceInterruptedOnDate = raw.serviceInterruptedOnDate;
    this.startDate = raw.startDate;
    this.status = raw.status;
    this.webProfile = raw.webProfile;
  }
}

export interface CdhAccountRaw {
  category?: string;
  cdhAccountId?: string;
  currentAccountBalance?: InvoiceAmountRaw;
  infoProId?: string;
  isSummaryBilled?: false;
  lastInvoiceAmount?: InvoiceAmountRaw;
  name?: string;
  nextInvoiceDate?: string;
  serviceInterruptedOnDate?: string;
  startDate?: string;
  status?: string;
  webProfile?: string;
}

export class CorruptionError {
  public message: string;
  public code: string;

  constructor(raw: CorruptionErrorRaw) {
    this.code = raw.code;
    this.message = CorruptionErrorMessage[raw.code];
  }
}

export interface CorruptionErrorRaw {
  code?: string;
}

export class Account {
  public cdhAccount?: CdhAccount;
  public fisAccount?: FisAccount;
  public error?: CorruptionError;

  constructor(raw: AccountRaw) {
    if (raw.cdhAccount) {
      this.cdhAccount = new CdhAccount(raw.cdhAccount);
    }

    if (raw.fisAccount) {
      this.fisAccount = new FisAccount(raw.fisAccount);
    }

    if (raw.error) {
      this.error = new CorruptionError(raw.error);
    }
  }
}

export interface AccountRaw {
  cdhAccount?: CdhAccountRaw;
  fisAccount?: FisAccountRaw;
  error?: CorruptionErrorRaw;
}

export class AccountUnenrollRequest {
  public cdhAccountId: string;
  public user: {
    user_id: string;
    fis_account_id: string;
    user_metadata: {
      default_account: string;
    };
    app_metadata: {
      cdh_id: string;
      cdh_contact_profile_id: string;
      fis_username: string;
    };
  };

  constructor(user: User, account: CdhAccount) {
    this.cdhAccountId = account.cdhAccountId;

    this.user = {
      user_id: user.userId,
      fis_account_id: `30${account.infoProAccountId}`,
      user_metadata: {
        default_account: user.userMetadata.defaultAccount
      },
      app_metadata: {
        cdh_id: user.appMetadata.cdhId,
        cdh_contact_profile_id: user.appMetadata.cdhContactProfileId,
        fis_username: user.appMetadata.fisUsername
      }
    };
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorPageComponent } from '@container/error-page/error-page.component';
import { HomeComponent } from '@container/home/home.component';
import { PageNotFoundComponent } from '@container/page-not-found/page-not-found.component';
import { PaymentDetailsViewComponent } from '@container/payment-details-view/payment-details-view.component';
import { PaymentComponent } from '@container/payment/payment.component';
import { TopicComponent } from '@container/topic/topic.component';
import { TroubleshootingGuideComponent } from '@container/troubleshooting-guide/troubleshooting-guide.component';
import { PaymentDetailsResolver } from '@resolver/payment-details/payment-details-resolver.service';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  {
    path: '',
    pathMatch: 'prefix',
    runGuardsAndResolvers: 'always',
    children: [
      // Payments routes
      {
        path: 'payment/new',
        component: PaymentComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'account/:fisAccountId/payments',
        component: PageNotFoundComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'account/:fisAccountId/payment/:paymentReferenceId',
        component: PaymentDetailsViewComponent,
        resolve: { paymentDetails: PaymentDetailsResolver },
        canActivate: [MsalGuard]
      }
    ]
  },
  { path: 'topics/:id', component: TopicComponent },
  { path: 'troubleshooting-guide', component: TroubleshootingGuideComponent },
  { path: 'permission-denied', component: ErrorPageComponent },
  { path: 'error/:code', component: ErrorPageComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  declarations: [PageNotFoundComponent],
  exports: [RouterModule]
})
export class AppRoutingModule {}

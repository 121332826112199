import { Component, Input } from '@angular/core';
import { ModelFormGroup } from '@interface/form-type.model';
import { PaymentMethodInfo } from '@interface/payment-method';

@Component({
  selector: 'app-bank-check-image',
  templateUrl: './bank-check-image.component.html',
  styleUrls: ['./bank-check-image.component.scss']
})
export class BankCheckImageComponent {
  @Input() public parentForm: ModelFormGroup<PaymentMethodInfo>;
  @Input() public maskedAccountNumber: string;

  public getName() {
    return this.parentForm.value.bankInfo.accountHolderName || 'Account Holder Name';
  }

  public getAccountNumber() {
    return this.maskedAccountNumber ? this.maskedAccountNumber : 'XXXXXXXXXXXX';
  }

  public getRoutingNumber() {
    return this.parentForm.value.bankInfo.routingNumber || 'XXXXXXXXXXXX';
  }
}

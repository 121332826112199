export interface Amount {
  amount: number;
  currency: string;
  display: string;
}

export enum AccountType {
  VISA = 1,
  MASTERCARD,
  CHECKING,
  SAVINGS,
  AMEX,
  DISCOVER,
  PINLESS_DEBIT,
  JCB
}

export class PaymentDetails {
  public accountHolderName: string;
  public accountNumberLastFour: string;
  public accountNumberMasked: string;
  public accountType: AccountType;
  public amount: Amount;
  public createdDate: string;
  public customerName: string;
  public date: string;
  public expiryMonth: string;
  public expiryYear: string;
  public fisAccountId: string;
  public paymentReferenceId: string;
  public paymentStatus: string;
  public status: string;
  public totalAmount: number;

  constructor(raw: PaymentDetailsRaw) {
    this.accountHolderName = raw.accountHolderName;
    this.accountNumberLastFour = raw.accountNumberLastFour;
    this.accountNumberMasked = raw.accountNumberMasked;
    this.accountType = AccountType[raw.accountType];
    this.amount = raw.amount;
    this.createdDate = raw.createdDate;
    this.customerName = raw.customerName;
    this.date = raw.date;
    this.expiryMonth = raw.expiryMonth;
    this.expiryYear = raw.expiryYear;
    this.fisAccountId = raw.accountId;
    this.paymentReferenceId = raw.paymentId;
    this.paymentStatus = raw.paymentStatus;
    this.status = raw.status;
    this.totalAmount = parseFloat(raw.totalAmount);
  }
}

export interface PaymentDetailsRaw {
  accountHolderName: string;
  accountId: string;
  accountNumberLastFour: string;
  accountNumberMasked: string;
  accountType: string;
  amount: Amount;
  createdDate: string;
  customerName: string;
  date: string;
  expiryMonth: string;
  expiryYear: string;
  id: string;
  paymentId: string;
  paymentStatus: string;
  status: string;
  totalAmount: string;
}

export interface PaymentDetailsParams {
  fisAccountId: string;
  paymentReferenceId: string;
}

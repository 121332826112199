import { Inject, Injectable } from '@angular/core';
import { troubleshootingTopicsToken } from '@service/troubleshooting/troubleshooting.constants';

export interface Update {
  date: string;
  description: string;
  id: string;
  title: string;
  topic: string;
}

export interface Topic {
  anchor?: string;
  id: string;
  link?: string;
  title: string;
  updates: Update[];
}

@Injectable()
export class TroubleshootingService {
  private topics: Topic[];

  constructor(@Inject(troubleshootingTopicsToken) private readonly troubleshootingTopics) {
    this.topics = this.addIdsFromTitles(this.troubleshootingTopics as Topic[], null);
  }

  public getTopics(): Topic[] {
    return this.topics;
  }

  public getTopic(id: string): Topic {
    return this.topics.find((topic) => topic.id === id);
  }

  public getUpdates() {
    let flattened = [];

    for (const topic of this.topics) {
      if (Array.isArray(topic.updates)) {
        flattened = flattened.concat(this.flattenArray(topic.updates));
      }
    }

    return flattened;
  }

  private flattenArray(arr) {
    let flattened = [];

    for (const item of arr) {
      if (Array.isArray(item)) {
        flattened = flattened.concat(this.flattenArray(item));
      } else {
        flattened.push(item);
      }
    }

    return flattened;
  }

  private addIdsFromTitles(items, topic) {
    const result = [];

    for (const item of items) {
      const mapped = {
        ...item,
        id: item.title.replace(/\s+/g, '-').toLowerCase(),
        topic
      };

      for (const prop in item) {
        if (item.hasOwnProperty(prop) && prop !== 'title') {
          mapped[prop] = item[prop];
        }
      }

      if (item.updates) {
        mapped['updates'] = this.addIdsFromTitles(item.updates, mapped.id);
      }

      result.push(mapped);
    }

    return result;
  }
}

import { Component, OnInit } from '@angular/core';
import {
  Topic,
  TroubleshootingService,
  Update
} from '@service/troubleshooting/troubleshooting.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public topics: Topic[];
  public updates: Update[];

  constructor(private troubleshooting: TroubleshootingService) {}

  public ngOnInit() {
    this.topics = this.troubleshooting.getTopics();
    this.updates = this.troubleshooting.getUpdates();
  }
}

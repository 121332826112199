export class FisPaymentMethod {
  public isCredit: boolean;
  public accountNickName: string;
  public accountNumberMasked: string;
  public accountNumberLastFour: string;
  public paymentMethodId: string;
  public accountHolderName: string;
  public accountType: string;
  public expiryMonth: string;
  public expiryYear: string;

  constructor(raw: FisPaymentMethodRaw) {
    this.isCredit = raw.isCredit;
    this.accountNickName = raw.accountNickName;
    this.accountNumberMasked = raw.accountNumberMasked;
    this.accountNumberLastFour = raw.accountNumberLastFour;
    this.paymentMethodId = raw.paymentMethodId;
    this.accountHolderName = raw.accountHolderName;
    this.accountType = raw.accountType;
    this.expiryMonth = raw.expiryMonth;
    this.expiryYear = raw.expiryYear;
  }
}

export interface FisPaymentMethodRaw {
  isCredit?: boolean;
  accountNickName?: string;
  accountNumberMasked?: string;
  accountNumberLastFour?: string;
  paymentMethodId?: string;
  accountHolderName?: string;
  accountType?: string;
  expiryMonth?: string;
  expiryYear?: string;
}

import { ResourceLoader } from '@angular/compiler';
import { COMPILER_OPTIONS, enableProdMode, Injectable } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env';
import { AppModule } from './app/app.module';

const { apigeeApiKey, apigeeEndpoint } = environment;

if (environment.production) {
  enableProdMode();
}

@Injectable()
export class CustomResourceLoader extends ResourceLoader {
  public async get(url: string): Promise<string> {
    if (url.includes(apigeeEndpoint)) {
      const headers = new Headers();

      headers.append('x-api-key', apigeeApiKey);
      headers.append('Content-type', 'text/html');
      const request = new Request(url, {
        method: 'GET',
        headers
      });

      return fetch(request).then((resp) => resp.text());
    }

    return fetch(url).then((resp) => resp.json());
  }
}

platformBrowserDynamic([
  {
    provide: COMPILER_OPTIONS,
    useValue: {
      providers: [
        {
          provide: ResourceLoader,
          useClass: CustomResourceLoader,
          deps: []
        }
      ]
    },
    multi: true
  }
])
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.handleHttpError(err);
        }

        return throwError(err);
      })
    );
  }

  private handleHttpError(err: HttpErrorResponse) {
    switch (err.status) {
      case 503:
        this.handle503();
        break;

      case 0:
        // This can happen due to simple network errors, a CORS failure, or other
        // reasons. We cannot differentiate CORS failures b/c of browser security
        this.handleUnknownError();
        break;
    }
  }

  private handle503() {
    this.router.navigate(['error', 503], {
      queryParams: { r: btoa(this.router.url) }
    });
  }

  private handleUnknownError() {
    this.router.navigate(['error', 0], {
      queryParams: { r: btoa(this.router.url) }
    });
  }
}

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPositiveNumber]'
})
export class PositiveNumberDirective {
  private hideKeys: string[] = ['e', 'E', '-', '+'];

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if (this.hideKeys.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  }
}

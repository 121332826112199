import { Component, Input } from '@angular/core';
import { Update } from '@service/troubleshooting/troubleshooting.service';

@Component({
  selector: 'app-recent-update-card',
  templateUrl: './recent-update-card.component.html',
  styleUrls: ['./recent-update-card.component.scss']
})
export class RecentUpdateCardComponent {
  @Input() public update: Update;

  public dismissed = false;

  public dismiss() {
    this.dismissed = true;
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env';

import { CreditCardInfoFormRaw } from '@interface/form-type.model';
import { CreditCardValidators } from '@validator/credit-card-validators';

// README: For whatever reason, Angular doesn't like this template literal in the Component
// decorator option object, but it works if you pre-define it.
const templateUrl = `${environment.apigeeEndpoint}/v4/ccForm`;

@Component({
  selector: 'app-credit-card-info',
  templateUrl,
  styleUrls: ['./credit-card-info.component.scss']
})
export class CreditCardInfoComponent implements OnInit {
  @Input() public parentForm: FormGroup<CreditCardInfoFormRaw>;
  @Output() public enterCvv = new EventEmitter();
  @Output() public exitCvv = new EventEmitter();
  @ViewChild('cvvInput', { static: true }) public cvvInput: ElementRef;

  public creditCardNumberMask = '0000000000000000';

  public cvvMask = '0009';

  public creditCardExpirationMask = 'M';

  public creditCardName = new FormControl('');
  public creditCardNumber = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{13}(\s?|\d?){3}$/)
  ]);
  public creditCardExpiration = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(1[0-2]|[1-9]|0[1-9])\/\d\d$/),
    CreditCardValidators.isExpired
  ]);
  public creditCardMonth = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(1[0-2]|[1-9]|0[1-9])$/)
  ]);
  public creditCardYear = new FormControl('', [Validators.required, Validators.pattern(/^\d\d$/)]);
  public creditCardCvv = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{3}(\s?|\d?)$/)
  ]);

  public expiryDatePattern = {
    M: { pattern: /[0-1]/ },
    N: { pattern: /[0-2]/ },
    '0': { pattern: /\d/ },
    P: { pattern: /[1-9]/ }
  };

  public ngOnInit(): void {
    this.parentForm.addControl('creditCardName', this.creditCardName);
    this.parentForm.addControl('creditCardNumber', this.creditCardNumber);
    this.parentForm.addControl('creditCardExpiration', this.creditCardExpiration);
    this.parentForm.addControl('creditCardMonth', this.creditCardMonth);
    this.parentForm.addControl('creditCardYear', this.creditCardYear);
    this.parentForm.addControl('creditCardCvv', this.creditCardCvv);

    this.creditCardExpiration.valueChanges.subscribe((value) => {
      this.onCardExpiryChange(value);

      if (this.creditCardExpiration.valid) {
        const [month, year] = value.split('/');

        this.creditCardMonth.setValue(month);
        this.creditCardYear.setValue(year);
      } else {
        this.creditCardMonth.reset('');
        this.creditCardYear.reset('');
      }
    });

    (this.cvvInput.nativeElement as HTMLInputElement).addEventListener('focus', () => {
      this.enterCvv.emit();
    });
    (this.cvvInput.nativeElement as HTMLInputElement).addEventListener('blur', () => {
      this.exitCvv.emit();
    });
  }

  public onCardExpiryChange(cardExpiry: string) {
    const cardExpiryValue = cardExpiry.replace(/\D/g, '').slice(0, 4);

    this.creditCardExpirationMask = this.getExpMask(cardExpiryValue);
  }

  private getExpMask(rawValue: string) {
    if (!rawValue) {
      return 'M';
    }

    if (rawValue.substring(0, 1) === '1') {
      return 'MN/00';
    }

    return 'MP/00';
  }
}

import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]',
  providers: [CurrencyPipe]
})
export class CurrencyFormatDirective implements OnInit {
  private el: HTMLInputElement;
  @Output() public rawChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  public ngOnInit() {
    this.formatValue();
  }

  @HostListener('blur', ['$event'])
  public onBlur() {
    this.formatValue();
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    const hasDecimal = this.el.value.indexOf('.') > -1;

    if (event.key === '.') {
      if (hasDecimal || this.el.value.length === 0) {
        event.preventDefault();
      }
    }
  }

  private formatValue() {
    if (this.el.value) {
      this.el.value = parseFloat(this.el.value).toFixed(2);
    }
  }
}

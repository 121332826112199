import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMaskBankAccountNumber]'
})
export class MaskBankAccountNumberDirective {
  @Output() public num = new EventEmitter();
  public accountNumberStored: any = null;

  constructor(private el: ElementRef) {}

  @HostListener('focus') public onFocus() {
    if (this.accountNumberStored) {
      this.el.nativeElement.value = this.accountNumberStored;
      this.num.emit(this.el.nativeElement.value);
    }
  }

  @HostListener('blur') public onBlur() {
    let el = this.el.nativeElement.value;

    this.accountNumberStored = el;
    el = el.replace(/.(?=.{4,16}$)/g, '*');
    const accountNumberwithMasked = this.accountNumberStored.replace(/.(?=.{4,16}$)/g, 'X');

    this.el.nativeElement.value = el;
    this.num.emit(accountNumberwithMasked?.length > 0 ? accountNumberwithMasked : 'XXXXXXXXXXXX');
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { PaymentDetails } from '@interface/payment-details';
import { FisService } from '@service/fis/fis.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailsResolver implements Resolve<PaymentDetails> {
  constructor(private fs: FisService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<PaymentDetails> {
    const fisAccountId = route.paramMap.get('fisAccountId');
    const paymentReferenceId = route.paramMap.get('paymentReferenceId');

    return this.fs.getFisPaymentDetails({
      fisAccountId,
      paymentReferenceId
    });
  }
}

import { Injectable } from '@angular/core';

interface CustomWindow extends Window {
  dataLayer: any[];
}

declare const window: CustomWindow;

@Injectable()
export class AnalyticsService {
  public analytics;

  public trackPaymentSubmission(paymentData) {
    if (paymentData) {
      const analyticsData = {
        paymentMethod: this.getPaymentMethodType(paymentData),
        amountChanged: paymentData.originalAmount !== paymentData.paymentAmount.amount,
        amount: paymentData.paymentAmount.amount,
        paymentStatus: paymentData.paymentStatus,
        status: paymentData.status,
        timeOnPaymentPage: paymentData.timeOnPaymentPage
      };

      window.dataLayer.push({
        event: 'payment-submission',
        'payment-info': analyticsData
      });
    }
  }

  private getPaymentMethodType(paymentData) {
    let paymentMethodType;

    if (
      paymentData.hasOwnProperty('paymentMethodInfo') &&
      paymentData.paymentMethodInfo.hasOwnProperty('paymentMethodId')
    ) {
      paymentMethodType = 'Saved Payment Method';
    } else if (
      paymentData.hasOwnProperty('paymentMethodInfo') &&
      paymentData.paymentMethodInfo.hasOwnProperty('cardInfo')
    ) {
      paymentMethodType = 'CreditCard';
    } else if (
      paymentData.hasOwnProperty('paymentMethodInfo') &&
      paymentData.paymentMethodInfo.hasOwnProperty('bankInfo')
    ) {
      paymentMethodType = 'Bank Account';
    }

    return paymentMethodType;
  }
}

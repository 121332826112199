import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env';
import { PaymentEvent } from '@interface/payment-event';

@Injectable()
export class PaymentEventsService {
  constructor(private http: HttpClient) {}

  public sendEvent(paymentEvent: PaymentEvent): Observable<boolean> {
    return this.http.post(`${environment.apiBaseUrl}/events`, paymentEvent).pipe(map(() => true));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

import { FisAccountTypes } from '@enum/fis-account-types';
import { CountryCode, State } from '@interface/location';
import { BillingInfo, PaymentMethodInfo, PaymentMethodRequest } from '@interface/payment-method';
import { PopulatedErrorStateMatcher } from '@matcher/populated-error-state';
import { ApigeeService } from '@service/apigee/apigee.service';
import { FisService } from '@service/fis/fis.service';
import { InputPatternService } from '@service/input-pattern/input-pattern.service';
import { LocationService } from '@service/locations/location.service';
import { CreditCardInfoFormRaw, ModelFormGroup } from '@interface/form-type.model';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
  providers: [{ provide: ErrorStateMatcher, useClass: PopulatedErrorStateMatcher }]
})
export class AddCreditCardComponent implements OnInit {
  @Input() public hideSubmit = false;
  @Input() public hideSave = true;
  @Input() public parentForm: ModelFormGroup<PaymentMethodInfo>;
  @Output() public closeCreditCardWindow = new EventEmitter<boolean>();
  @Output() public checkValidity: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public states: State[];
  public isSubmitting = false;
  public fisUsername: string;
  public form: ModelFormGroup<PaymentMethodInfo>;
  public cardInfoForm: FormGroup<CreditCardInfoFormRaw>;
  public billingInfo: BillingInfo;
  public billingInfoForm: ModelFormGroup<BillingInfo>;
  public externalIdControl = new FormControl('');
  public accountNickNameControl = new FormControl('');
  public accountTypeControl = new FormControl<string | null>(null);
  public cvvFocus$ = new Subject<boolean>();
  public customPatterns = {};
  public zipCodeMask = '00000-0000';
  public canadaZipCodeMask = 'F0L 0L0';

  protected submissionError: string = null;

  public countries = [
    { name: 'United States', code: 'US' },
    { name: 'Canada', code: 'CA' }
  ];

  private _postalCodeMask: Array<RegExp | string> = [];
  public canadaZipCodePattern = {
    '0': { pattern: /\d/ },
    F: { pattern: /[ABCEGHJKLMNPRSTVXY]/i },
    L: { pattern: /[ABCEGHJKLMNPRSTVWXYZ]/i }
  };

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private locationService: LocationService,
    private fisService: FisService,
    private apigeeService: ApigeeService,
    private inputMaskService: InputPatternService
  ) {
    this.setValidationPatterns();
    this.billingInfo = new BillingInfo();
    this.readQueryParams();
  }

  public setValidationPatterns() {
    this.customPatterns['A'] = this.inputMaskService.safeASCII;
  }

  public readQueryParams() {
    this.route.queryParams.subscribe(
      ({
        cdhCustomerId,
        firstName,
        middleInitial,
        lastName,
        addressLine1,
        addressLine2,
        city,
        postalCode,
        stateCode,
        countryCode
      }) => {
        if (cdhCustomerId) {
          this.fisService
            .getFisUsername(cdhCustomerId)
            .subscribe((fisUsername) => (this.fisUsername = fisUsername));
        }

        this.billingInfo.firstName = firstName || '';
        this.billingInfo.middleInitial = middleInitial || '';
        this.billingInfo.lastName = lastName || '';
        this.billingInfo.addressLine1 = addressLine1 || '';
        this.billingInfo.addressLine2 = addressLine2 || '';
        this.billingInfo.city = city || '';
        this.billingInfo.stateCode = stateCode || '';
        this.billingInfo.postalCode = postalCode || '';

        if (countryCode) {
          this.billingInfo.countryCode =
            (CountryCode[countryCode.toUpperCase()] as CountryCode) || CountryCode.US;
        } else {
          this.billingInfo.countryCode = CountryCode.US;
        }
      }
    );
  }

  public createForm() {
    this.form = this.parentForm ? this.parentForm : this.fb.group({});

    this.billingInfoForm = this.fb.group({
      firstName: [this.billingInfo.firstName, [Validators.required, Validators.maxLength(50)]],
      middleInitial: [this.billingInfo.middleInitial, Validators.maxLength(1)],
      lastName: [this.billingInfo.lastName, [Validators.required, Validators.maxLength(50)]],
      addressLine1: [
        this.billingInfo.addressLine1,
        [Validators.required, Validators.maxLength(50)]
      ],
      addressLine2: [this.billingInfo.addressLine2, Validators.maxLength(50)],
      city: [this.billingInfo.city, [Validators.required, Validators.maxLength(32)]],
      stateCode: [this.billingInfo.stateCode, Validators.required],
      postalCode: [this.billingInfo.postalCode, Validators.required],
      countryCode: [this.billingInfo.countryCode, Validators.required]
    });

    this.cardInfoForm = this.fb.group<CreditCardInfoFormRaw>({
      billingInfo: this.billingInfoForm,
      saveCard: this.fb.control(false)
    });

    this.form.addControl('externalId', this.externalIdControl);
    this.form.addControl('accountNickName', this.accountNickNameControl);
    this.form.addControl('accountType', this.accountTypeControl);
    this.form.addControl('cardInfo', this.cardInfoForm);

    this.setPostalMask(this.billingInfo.countryCode);
  }

  public onChanges() {
    this.form.valueChanges.subscribe(() => {
      this.checkValidity.emit(this.form.valid);
    });

    this.billingInfoForm.controls.countryCode.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((code: CountryCode) => {
        if (code) {
          this.states = this.locationService.getStates(code);
          this.billingInfoForm.controls.stateCode.reset();
          this.billingInfoForm.controls.postalCode.reset();
          this.setPostalMask(code);
        }
      });
  }

  public ngOnInit() {
    this.states = this.locationService.getStates(this.billingInfo.countryCode);
    this.createForm();
    this.onChanges();
  }

  public addCreditCard() {
    const paymentMethodInfo = new PaymentMethodInfo(this.form.value);
    const cardInfo = paymentMethodInfo.cardInfo;

    if (!paymentMethodInfo.accountType && cardInfo) {
      paymentMethodInfo.accountType =
        FisAccountTypes[this.apigeeService.creditCardType(cardInfo.creditCardNumber)];
    }

    // adjust credit card fields to be strings
    const now = new Date();

    cardInfo.creditCardMonth = cardInfo.creditCardMonth.toString().padStart(2, '0');
    cardInfo.creditCardYear =
      now.getFullYear().toString().substring(0, 2) + cardInfo.creditCardYear;

    cardInfo.creditCardCvv = cardInfo.creditCardCvv.trim();
    cardInfo.creditCardNumber = cardInfo.creditCardNumber.trim();
    cardInfo.billingInfo.postalCode = cardInfo.billingInfo.postalCode.trim();

    // create nickname and credit card name
    paymentMethodInfo.accountNickName = this.apigeeService.creditCardNickName(
      paymentMethodInfo.accountType,
      cardInfo.creditCardNumber
    );

    // get card name from billing info
    cardInfo.creditCardName = this.apigeeService.creditCardName(
      cardInfo.billingInfo.firstName,
      cardInfo.billingInfo.middleInitial,
      cardInfo.billingInfo.lastName
    );

    const paymentMethodRequest = new PaymentMethodRequest({
      hasAgreedToStore: 'true',
      hasAgreedToUse: 'true',
      externalId: this.fisUsername,
      paymentMethodInfo
    });

    this.submissionError = null;
    this.isSubmitting = true;
    this.form.disable({ emitEvent: false });

    this.apigeeService
      .createPaymentMethod(paymentMethodRequest)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe(
        (res) => {
          if (res?.errors) {
            this.submissionError =
              res?.errors?.[0]?.ApplMsgTxt || 'There was an error processing your request';

            this.form.enable({ emitEvent: false });
          } else {
            this.closeCreditCardWindow.emit(true);
          }
        },
        (error) => {
          this.submissionError =
            error?.error?.errors?.[0]?.message ||
            error?.statusText ||
            'There was an error processing your request';

          this.form.enable({ emitEvent: false });
        }
      );
  }

  public isCanadianCode(code?: CountryCode) {
    code = code || this.billingInfoForm.controls.countryCode.value;

    return code === CountryCode.CA;
  }

  private setPostalMask(countryCode: CountryCode) {
    if (this.isCanadianCode(countryCode)) {
      this.billingInfoForm.controls.postalCode.setValidators([
        Validators.required,
        Validators.pattern(
          /^([ABCEGHJKLMNPRSTVXY])(\d)([ABCEGHJKLMNPRSTVWXYZ])( )(\d)([ABCEGHJKLMNPRSTVWXYZ])(\d)$/i
        )
      ]);

      const firstLetter = /[ABCEGHJKLMNPRSTVXY]/i;
      const letter = /[ABCEGHJKLMNPRSTVWXYZ]/i;
      const number = /\d/;

      this._postalCodeMask = [firstLetter, number, letter, ' ', number, letter, number];

      return;
    }

    this._postalCodeMask = [...Array(5).fill(/\d/), /-/, ...Array(4).fill(/\d/)];
    this.billingInfoForm.controls.postalCode.setValidators([
      Validators.required,
      Validators.pattern(/^\d{5}(?:-\d{4})?$/)
    ]);
  }
}

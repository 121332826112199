import { Component, Input } from '@angular/core';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() public bannerMessage = '';
  @Input() public bannerHeading = '';

  public bannerIcon = faExclamation;
}

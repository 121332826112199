import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-troubleshooting-guide',
  templateUrl: './troubleshooting-guide.component.html',
  styleUrls: ['./troubleshooting-guide.component.scss']
})
export class TroubleshootingGuideComponent implements OnInit, AfterViewInit {
  private fragment: string;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => (this.fragment = fragment));
  }

  public ngAfterViewInit(): void {
    document.querySelector(`#${this.fragment}`).scrollIntoView(true);
    window.scrollBy(0, -10);
  }
}

import { FormControl, Validators } from '@angular/forms';

export class CreditCardValidators extends Validators {
  public static isExpired = (control: FormControl) => {
    if (control.value) {
      const currentDate = new Date();
      const currentYear = parseInt(currentDate.getFullYear().toString().substring(2, 4), 10);
      const currentMonth = currentDate.getMonth() + 1;
      const controlMonth = parseInt(control.value.split('/')[0], 10);
      const controlYear = control.value.split('/')[1]
        ? parseInt(control.value.split('/')[1], 0)
        : currentYear + 1;

      const expired =
        currentYear > controlYear || (currentYear === controlYear && currentMonth > controlMonth);

      if (expired) {
        return { ccExpired: true };
      }
    }

    return null;
  };
}

import { Component, Input, OnChanges } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnChanges {
  @Input() protected content: string;
  @Input() protected errorCode: number;
  @Input() protected errorIcon: string;
  @Input() protected heading: string;

  @Input() private returnUrl: UrlTree;

  protected returnPath: string;
  protected returnParams: Params;
  protected returnFragement: string | null;

  private icons = {
    0: 'warning',
    503: 'cloud_off'
  };

  private messages = {
    0: 'An unknown error occurred. Please check again later.',
    503: 'A connection error occurred. Please check again later.'
  };

  private titles = {
    0: 'Unknown Error',
    503: '503 Server Error'
  };

  constructor(private router: Router) {}

  public ngOnChanges() {
    if (typeof this.errorCode !== 'undefined') {
      if (!this.heading && this.titles[this.errorCode]) {
        this.heading = this.titles[this.errorCode];
      }

      if (!this.errorIcon && this.icons[this.errorCode]) {
        this.errorIcon = this.icons[this.errorCode];
      }

      if (!this.content && this.messages[this.errorCode]) {
        this.content = this.messages[this.errorCode];
      }
    }

    if (this.returnUrl) {
      // Generate the appropriate data for the routerLink.
      // There's bound to be a cleaner way to do this though.
      this.returnParams = this.returnUrl.queryParams;
      this.returnFragement = this.returnUrl.fragment;
      this.returnUrl.queryParams = {};
      this.returnUrl.fragment = null;
      this.returnPath = this.router.serializeUrl(this.returnUrl);
    }
  }
}

import { AccountType, PaymentDetails } from './payment-details';

export class PaymentEvent {
  public quoteNumber: string;
  public accountNumber: string;
  public accountType: string;
  public billReferenceId: string;
  public paymentAmount: string;
  public paymentDate: string;
  public paymentReferenceId: string;
  public status: string;
  public customerName: string;
  public nonInvoicedAccountNumber: string;
  public userName: string;

  constructor(paymentDetails: PaymentDetails, extra: PaymentEventExtra) {
    this.accountNumber = paymentDetails.fisAccountId;
    this.accountType = AccountType[paymentDetails.accountType];
    this.paymentAmount = String(paymentDetails.totalAmount);
    this.paymentDate = paymentDetails.date;
    this.paymentReferenceId = paymentDetails.paymentReferenceId;
    this.status = paymentDetails.status;
    this.customerName = paymentDetails.customerName;

    this.quoteNumber = extra.quoteNumber;
    this.billReferenceId = extra.invoiceNumber;
    this.nonInvoicedAccountNumber = extra.nonInvoicedAccountNumber;
    this.userName = extra.userName;
  }
}

export interface PaymentEventExtra {
  quoteNumber: string;
  invoiceNumber: string;
  nonInvoicedAccountNumber?: string;
  userName: string;
}

import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Injectable } from '@angular/core';

/**
 * Used for indicating when Angular Material should mark a field in an error
 * state.
 *
 * Flag a field when it is dirty, has been touched, or is populated with a
 * non-empty, but invalid value.
 */
@Injectable()
export class PopulatedErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null): boolean {
    const isPopulated =
      control.value !== null && control.value !== undefined && control.value !== '';

    return !!(control && control.invalid && (control.dirty || control.touched || isPopulated));
  }
}

import { InjectionToken } from '@angular/core';

export const troubleshootingTopics = [
  {
    title: 'New User Registration',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'registration'
  },
  {
    title: 'Password Strength Critera',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'password-critera'
  },
  {
    title: 'Email Verification',
    updates: [
      {
        date: '2018-5-7',
        description: 'We have updated the Services page to no longer display closed containers.',
        title: 'Closed Containers Display'
      }
    ],
    link: '/troubleshooting-guide',
    anchor: 'email-verification'
  },
  {
    title: 'Reset Password',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'reset-password'
  },
  {
    title: 'Invalid User ID',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'invalid-user'
  },
  {
    title: 'Customer Online Payment',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'customer-payment'
  },
  {
    title: 'FIS Online Payment',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'fis-payment'
  },
  {
    title: 'Payment Method',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'payment-method'
  },
  {
    title: 'Autopay',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'autopay'
  },
  {
    title: 'Auto Linking Accounts',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'auto-link'
  },
  {
    title: 'Manual Linking Accounts',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'manual-link'
  },
  {
    title: 'User Management',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'user-management'
  },
  {
    title: 'Account Managment',
    updates: [],
    link: '/troubleshooting-guide',
    anchor: 'account-management'
  }
];

export type TroubleShootingConstants = typeof troubleshootingTopics;

export const troubleshootingTopicsToken = new InjectionToken<TroubleShootingConstants>(
  'troubleshootingTopicsToken'
);

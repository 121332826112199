import { Component, Input } from '@angular/core';
import { AccountType, PaymentDetails } from '@interface/payment-details';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent {
  @Input() public paymentDetails: PaymentDetails;
  @Input() public invoiceNumber: string;
  @Input() public quoteNumber?: string;
  public AccountType = AccountType;
}

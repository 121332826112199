import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appConfirm]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ConfirmValidatorDirective,
      multi: true
    }
  ]
})
export class ConfirmValidatorDirective implements Validator {
  @Input() public with: FormControl;

  public validate(c: FormControl): { [key: string]: any } | null {
    return confirmValidator(this.with)(c);
  }
}

export function confirmValidator(source: FormControl): ValidatorFn {
  return (control: FormControl): { [key: string]: any } | null => {
    const matches = source.value === control.value;

    return matches ? null : { confirm: { value: control.value } };
  };
}

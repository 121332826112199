import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FisAccountTypes } from '@enum/fis-account-types';
import { CreditCardInfoFormRaw } from '@interface/form-type.model';
import { ApigeeService } from '@service/apigee/apigee.service';
import { Subject } from 'rxjs';

const rotateCard = [
  state('shown', style({ transform: 'rotateY(0)' })),
  state('hidden', style({ transform: 'rotateY(90deg)' })),
  transition('shown => hidden', animate('200ms ease-out')),
  transition('hidden => shown', animate('200ms ease-in'))
];

@Component({
  animations: [trigger('cardFront', rotateCard), trigger('cardBack', rotateCard)],
  selector: 'app-credit-card-image',
  templateUrl: './credit-card-image.component.html',
  styleUrls: ['./credit-card-image.component.scss']
})
export class CreditCardImageComponent implements OnInit {
  @Input() public parentForm: FormGroup<CreditCardInfoFormRaw>;
  @Input() public rotateTrigger$: Subject<boolean>;

  public cardFrontState = 'shown';
  public cardBackState = 'hidden';
  public flippingToBack = false;
  public flippingToFront = false;

  constructor(private apigeeService: ApigeeService) {}

  public rotateCard(value: boolean) {
    if (value && !this.hasFrontCvv()) {
      this.cardFrontState = 'hidden';
      this.flippingToBack = true;
    } else {
      this.cardBackState = 'hidden';
      this.flippingToFront = true;
    }
  }

  public onAnimationEvent(event: AnimationEvent) {
    // ignore initial animation state
    if (event.fromState === 'void') {
      return;
    }

    if (this.flippingToBack) {
      this.flippingToBack = false;
      this.cardBackState = 'shown';
    }

    if (this.flippingToFront) {
      this.flippingToFront = false;
      this.cardFrontState = 'shown';
    }
  }

  public ngOnInit() {
    // watch for rotate trigger (eg. field focus/blur) and handle
    this.rotateTrigger$.subscribe((value) => {
      this.rotateCard(value);
    });
  }

  public getCardName(): string {
    const billingInfo = this.parentForm.controls.billingInfo.value;
    const cardName = this.apigeeService.creditCardName(
      billingInfo.firstName,
      billingInfo.middleInitial,
      billingInfo.lastName
    );

    return cardName === '' ? 'My Card' : cardName;
  }

  public hasCardNumber(): boolean {
    return !!this.parentForm.controls.creditCardNumber.value;
  }

  private ccFormat(creditCardNumber) {
    return creditCardNumber.replace(/(.{4})/g, '$1 ');
  }

  public getCardNumber(): string {
    return this.ccFormat(this.parentForm.controls.creditCardNumber.value) || 'XXXXXXXXXXXXXXX';
  }

  public getCardTypeClass(): string {
    return FisAccountTypes[
      this.apigeeService.creditCardType(this.parentForm.value.creditCardNumber)
    ].toLowerCase();
  }

  public getCardExp(): string {
    const cardMonth = this.parentForm.value.creditCardMonth;
    const cardYear = this.parentForm.value.creditCardYear;
    const cardExp = this.parentForm.value.creditCardExpiration;

    return cardMonth && cardYear ? cardExp : 'MM/YY';
  }

  public getCardCvv(): string {
    return this.parentForm.controls.creditCardCvv.value || '####';
  }

  public hasFrontCvv(): boolean {
    return (
      this.apigeeService.creditCardType(this.parentForm.value.creditCardNumber) ===
      FisAccountTypes.AMEX
    );
  }
}

export enum FisAccountTypes {
  VISA = 1,
  MASTERCARD = 2,
  CHECKING = 3,
  SAVINGS = 4,
  AMEX = 5,
  DISCOVER = 6,
  PINLESS_DEBIT = 7,
  JCB = 8
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { FisPaymentMethod } from '@interface/fis-payment-method';
import {
  PaymentDetails,
  PaymentDetailsParams,
  PaymentDetailsRaw
} from '@interface/payment-details';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FisService {
  public paymentMethodsSubject$: Subject<FisPaymentMethod[]> = new Subject();

  constructor(private http: HttpClient) {}

  public getFisUsername(cdhCustomerId: string): Observable<string> {
    const url = `${environment.apiBaseUrl}/cdh-users/${cdhCustomerId}/fis-username`;

    return this.http.get(url).pipe(map(({ data }: any) => data.fisUsername));
  }

  public getFisPaymentMethods(
    cdhCustomerId: string,
    refresh?: boolean
  ): Observable<FisPaymentMethod[]> {
    const url = `${environment.apiBaseUrl}/cdh-users/${cdhCustomerId}/fis-payment-methods${
      refresh ? '?refresh=true' : ''
    }`;

    return this.http.get(url, {}).pipe(
      map(({ data }: any) =>
        data.paymentMethods.map((paymentMethod) => new FisPaymentMethod(paymentMethod))
      ),
      tap((paymentMethods) => this.paymentMethodsSubject$.next(paymentMethods))
    );
  }

  public getFisPaymentDetails({
    fisAccountId,
    paymentReferenceId
  }: PaymentDetailsParams): Observable<PaymentDetails> {
    const url = `${environment.apiBaseUrl}/accounts/${fisAccountId}/payments/${paymentReferenceId}`;

    return this.http
      .get(url, {})
      .pipe(map(({ data }: any) => new PaymentDetails(data as PaymentDetailsRaw)));
  }
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appErrorMessage]'
})
export class ErrorMessageDirective implements OnInit {
  @Input() public form: FormGroup;
  @Input() public path: string;
  @Input() public messages: { [key: string]: string } = {};

  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    if (this.form && this.path) {
      this.form.valueChanges.subscribe(() => this.setErrorMessage());
    }

    this.setErrorMessage();
  }

  private setErrorMessage() {
    (this.el.nativeElement as HTMLElement).innerHTML = this.getErrorMessage();
  }

  public getErrorMessage() {
    if (this.form && this.path) {
      const control = this.form.get(this.path);

      if (control && control.errors) {
        const firstErrorName = Object.keys(control.errors)[0].trim();
        const firstError = control.errors[firstErrorName];

        if (this.messages && this.messages[firstErrorName]) {
          return this.messages[firstErrorName];
        }

        switch (firstErrorName) {
          case 'required':
            return 'Field is required.';

          case 'pattern':
            return 'Field is invalid.';

          case 'minlength':
            return `Requires at least ${firstError.requiredLength} character(s).`;

          case 'maxlength':
            return `Cannot exceed ${firstError.requiredLength} character(s).`;

          case 'min':
            return `Value must be ${firstError.min} or greater.`;

          case 'numericMinLength':
            return `Requires at least ${firstError.requiredLength} character(s).`;

          case 'numericMaxLength':
            return `Cannot exceed ${firstError.requiredLength} character(s).`;

          case 'ccExpired':
            return 'Card has expired.';

          case 'prefixedWith':
            return `Must start with ${firstError.expected}`;

          case 'confirm':
            return 'Confirmed value does not match.';
        }
      }
    }

    return '';
  }
}

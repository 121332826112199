import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '@env';
import { ModelFormGroup } from '@interface/form-type.model';
import { BankInfo } from '@interface/payment-method';
import { InputPatternService } from '@service/input-pattern/input-pattern.service';

// README: For whatever reason, Angular doesn't like this template literal in the Component
// decorator option object, but it works if you pre-define it.
const templateUrl = `${environment.apigeeEndpoint}/v4/achForm`;

@Component({
  selector: 'app-bank-info',
  templateUrl
})
export class BankInfoComponent implements OnInit {
  @Input() public parentForm: ModelFormGroup<BankInfo>;
  @Input() public accountHolderNameValue: string;
  @Output() public updatedAccountNumber = new EventEmitter<string>();

  public customPatterns = {};
  public routingNumberMask = '000000000';

  public accountNumberMask = '00000000000000000';

  public accountHolderName: FormControl<string>;
  public accountNumber = new FormControl('', Validators.required);
  public bankName = new FormControl('', [Validators.required, Validators.maxLength(32)]);
  public routingNumber = new FormControl('', [Validators.required, Validators.pattern(/^\d{9}$/)]);

  constructor(private inputMaskService: InputPatternService) {
    this.setValidationPatterns();
  }

  public ngOnInit() {
    this.accountHolderName = new FormControl(
      this.accountHolderNameValue || '',
      Validators.required
    );

    this.parentForm.addControl('accountHolderName', this.accountHolderName);
    this.parentForm.addControl('accountNumber', this.accountNumber);
    this.parentForm.addControl('bankName', this.bankName);
    this.parentForm.addControl('routingNumber', this.routingNumber);
    this.accountNumber.valueChanges.subscribe((value: string) => {
      this.updatedAccountNumber.emit(value);
    });
  }

  public setValidationPatterns() {
    this.customPatterns['A'] = this.inputMaskService.safeASCII;
  }
}

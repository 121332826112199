/* eslint:disable max-classes-per-file */

import { InvoiceAmount } from '@interface/account';
import { PaymentMethodInfo } from '@interface/payment-method';
import { CountryCode } from './location';

export class PaymentContact {
  public firstName?: string;
  public lastName?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public city?: string;
  public stateCode?: string;
  public postalCode?: string;
  public countryCode?: CountryCode;
  public email?: string;
  public contactInfo?: PaymentContactRaw;

  constructor(raw: PaymentContactRaw = {}) {
    this.firstName = raw.firstName || '';
    this.lastName = raw.lastName || '';

    if (raw.addressLine1) {
      this.addressLine1 = raw.addressLine1;
    }

    if (raw.addressLine2) {
      this.addressLine2 = raw.addressLine2;
    }

    if (raw.city) {
      this.city = raw.city;
    }

    if (raw.stateCode) {
      this.stateCode = raw.stateCode;
    }

    if (raw.postalCode) {
      this.postalCode = raw.postalCode;
    }

    if (raw.countryCode) {
      this.countryCode =
        (CountryCode[raw.countryCode.toUpperCase()] as CountryCode) || CountryCode.US;
    }

    if (raw.email) {
      this.email = raw.email;
    }
  }
}

export interface PaymentContactRaw {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateCode?: string;
  postalCode?: string;
  countryCode?: CountryCode;
  email?: string;
}

export class Payment {
  public accountId?: string;
  public billReferenceId?: string;
  public externalId?: string;
  public hasAgreedToStore?: boolean;
  public hasAgreedToUse?: boolean;
  public paymentAmount?: number;
  public paymentDate?: string;
  public paymentMethodInfo?: PaymentMethodInfo;
  public contactInfo?: PaymentContact;

  constructor(raw: PaymentRaw = {}) {
    this.paymentAmount = raw.paymentAmount || 0;
    this.accountId = raw.accountId || '';
    this.billReferenceId = raw.billReferenceId || '';
    this.externalId = raw.externalId || '';
    this.hasAgreedToStore = !!raw.hasAgreedToStore || false;
    this.hasAgreedToUse = !!raw.hasAgreedToUse || true;
    this.paymentDate = raw.paymentDate || '';
    this.paymentMethodInfo = raw.paymentMethodInfo || new PaymentMethodInfo();
    this.contactInfo = raw.contactInfo || new PaymentContact();
  }

  public toJSON() {
    return Object.assign({}, this, {
      hasAgreedToStore: `${this.hasAgreedToStore}`,
      hasAgreedToUse: `${this.hasAgreedToUse}`
    });
  }
}

export interface PaymentRaw {
  paymentAmount?: number;
  externalId?: string;
  accountId?: string;
  billReferenceId?: string;
  hasAgreedToStore?: string | boolean;
  hasAgreedToUse?: string | boolean;
  paymentDate?: string;
  paymentMethodInfo?: PaymentMethodInfo;
  contactInfo?: PaymentContact;
}

export class PaymentResult {
  public billReferenceId: string;
  public paymentDate: string;
  public paymentId: string;
  public paymentStatus: string;
  public status: string;
  public paymentAmount: InvoiceAmount;

  constructor(raw: PaymentResultRaw) {
    this.billReferenceId = raw.billReferenceId;
    this.paymentDate = raw.paymentDate;
    this.paymentId = raw.paymentId;
    this.paymentStatus = raw.paymentStatus;
    this.status = raw.paymentStatus;
    this.paymentAmount = new InvoiceAmount(raw.paymentAmount);
  }
}

export interface PaymentResultRaw {
  billReferenceId?: string;
  paymentDate?: string;
  paymentId?: string;
  paymentStatus?: string;
  status?: string;
  paymentAmount?: InvoiceAmount;
}

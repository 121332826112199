import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMaskCcInfo]'
})
export class MaskCcInfoDirective {
  @Output() public num = new EventEmitter();
  public ccNumberStored: any = null;

  private ccFormat(creditCardNumber) {
    return creditCardNumber.replace(/(.{4})/g, '$1 ');
  }

  private setElement(content: string) {
    document.getElementById('cardNumberFront').innerHTML = content;
  }

  constructor(private el: ElementRef) {}

  @HostListener('focus') public onFocus() {
    if (this.ccNumberStored) {
      this.el.nativeElement.value = this.ccNumberStored;
      this.setElement(this.ccFormat(this.el.nativeElement.value));
    }
  }

  @HostListener('blur') public onBlur() {
    let el = this.el.nativeElement.value;

    this.ccNumberStored = el;

    if (el.length === 0) {
      this.setElement('XXXXXXXXXXXXXXX');
    } else {
      el = el.replace(/.(?=.{4,}$)/g, '*');
      this.el.nativeElement.value = this.ccFormat(el);
      this.setElement(this.ccFormat(el));
      this.num.emit(this.ccNumberStored);
    }
  }
}
